import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout/Layout"
import { BlueBtn } from "../components/layout/styledComponents"
import Seo from "../components/seo"
import open from "../images/open.png"
import safe from "../images/safe.png"

const Boxes = styled.div`
  margin: 64px auto 128px;
  max-width: 880px;

  @media (min-width: 660px) {
    display: flex;
    justify-content: space-between;
  }
`

const Box = styled.div`
  background: ${props => props.theme.mercury};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 307px;
  margin: 0 auto;
  text-align: center;

  :last-of-type {
    margin-top: 64px;
  }

  h3 {
    margin: 32px 0;
  }

  p {
    margin: 0;
  }

  @media (min-width: 660px) {
    margin: 0;

    :last-of-type {
      margin-top: 0;
    }
  }
`

export default function SafetyMeasures() {
  return (
    <Layout learnMore>
      <Seo title="Safety Measures - Pikes Peak Oral Surgery & Dental Implant Center | Dental Implants and Wisdom Teeth Removals in Colorado Springs, CO" />
      <section
        className="container"
        css={css`
          margin: 128px auto 98px;
        `}
      >
        <h3>we are open</h3>
        <p>
          The entire Pikes Peak Oral Surgery & Dental Implant Center team is
          excited to resume both emergency and elective treatments again and
          fulfill your oral health needs. We are scheduling appointments now.
        </p>
        <Boxes>
          <Box>
            <img src={open} alt="open" />
            <h3>Our Doors Are Open For Our Community</h3>
            <p>
              We are now scheduling appointments. If you need oral surgery care,
              our team is here for you.
            </p>
          </Box>
          <Box>
            <img src={safe} alt="safety" />
            <h3>Committed to Keeping You Safe</h3>
            <p>
              We are taking additional measures to ensure the health and safety
              of our patients and staff while our office remains open.
            </p>
          </Box>
        </Boxes>
        <h3>Our Commitment to Health & Safety</h3>

        <p>
          As always, our practice takes every precaution to keep patients and
          staff safe and healthy. While we have always adhered to and exceeded
          the standards for decontamination set by OSHA, we are going one step
          further and incorporating the latest CDC guidelines.
        </p>

        <h3>The Measures We Are Taking To Protect Our Patients</h3>

        <p>
          We ask you to keep the following in mind prior to and during your
          appointment:
        </p>

        <ul>
          <li>We will schedule to maintain social distancing.</li>
          <li>
            We will continue to wear personal protective gear (gowns, gloves,
            N95 masks, and face shields) as outlined by the CDC.
          </li>
          <li>
            We will continue to use hospital-grade disinfectants to clean each
            treatment room before and after each patient.
          </li>
          <li>
            We will continue to practice the latest in sterilization protocols
            to maintain the health and safety of our patients.
          </li>
        </ul>

        <h3>How We Are Protecting the Safety of Our Staff & Doctors</h3>

        <p>
          We are taking additional steps toward ensuring the safety of our
          staff.
        </p>

        <li>
          If you or someone in your household has a cough or flu-like symptoms,
          we kindly ask that you reschedule your appointment to protect the
          health of our staff.
        </li>

        <p>
          <span
            css={css`
              font-weight: 700;
            `}
          >
            IMPORTANT MESSAGE TO PATIENTS:
          </span>{" "}
          Your doctor may request an online video consultation if you are
          displaying symptoms.
        </p>

        <p>
          At Pikes Peak Oral Surgery & Dental Implant Center, we appreciate the
          ongoing support from our patients, referring providers, families, and
          friends. Thank you for your patience and cooperation while these
          measures are put into effect.
        </p>

        <p>
          For questions or clarification about these measures, please contact us
          at (719) 264-6070.
        </p>
        <Link
          to="#contact"
          css={css`
            display: block;
            margin: 32px 0 0;
            text-align: center;
          `}
        >
          <BlueBtn>book an appointment</BlueBtn>
        </Link>
      </section>
    </Layout>
  )
}
